<template>
  <v-list class="services-list" expand>
    <div v-for="service in services" :key="service.id" class="services-list__item">
      <services-item
        :item="service"
        :can-update="canUpdate"
        :selected-items="selectedItems"
        :open-all="openAll"
        @select-item="$emit('select-item', $event)"
      />
    </div>
  </v-list>
</template>

<script>
import ServicesItem from '@/components/Services/Item.vue';

export default {
  name: 'ServicesList',

  components: { ServicesItem },

  inject: ['media'],

  props: {
    services: { type: Array, required: true },
    selectedItems: { type: Array, default: () => [] },
    openAll: { type: Boolean, default: false },
    canUpdate: { type: Boolean, required: true },
  },
};
</script>

<style lang="scss">
.services-list {
  &__item {
    width: 100%;

    .v-list-item {
      &:focus::before {
        opacity: 0;
      }
      &::before {
        background: #2317ff !important;

        @media screen and (max-width: 959px) {
          background: transparent !important;
        }
      }
    }

    &:first-child .v-list-group__header {
      border-radius: 4px 4px 0 0;
    }

    &:last-child .v-list-group__header {
      border-radius: 0 0 4px 4px;
    }

    .v-list-group {
      &__header {
        height: 64px;
        background: $--project-expansion-panel-background;
        border-bottom: 1px solid rgba($--blue-color-20, 0.15);
        letter-spacing: 0.05px;

        &::before {
          background: #2317ff !important;
          @media screen and (max-width: 959px) {
            background: transparent !important;
          }
        }

        &__append-icon {
          margin-left: 0 !important;
          min-width: 24px !important;
        }
      }

      &__items {
        .v-list-item {
          background: $--blue-color-60;
          border-bottom: 1px solid rgba($--blue-color-20, 0.15);
        }

        .button-add-service {
          background: $--blue-color-70;
          color: #00008d;
          font-size: 16px;
        }
      }
    }
  }

  &__checkbox {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 16px;
    transform: translate(0, -50%);
  }
}

.services {
  &__category {
    background: $--project-expansion-panel-background;
    letter-spacing: 0.05px;
  }

  &__with-category {
    background: $--blue-color-60;
  }

  &__button-add-service {
    background: $--blue-color-70;

    .v-btn__content {
      color: #00008d;
      font-size: 16px;
    }
  }

  &__category,
  &__with-category,
  &__button-add-service {
    border-bottom: 1px solid rgba($--blue-color-20, 0.15);
  }

  &__with-category,
  &__without-category {
    letter-spacing: 0.08px;
  }
}

.services-mobile-list {
  &__name {
    color: $--primary-color !important;
    min-width: 0 !important;
  }

  &__initial-amount {
    font-weight: $--font-weight-bold;
    font-size: 16px;
    line-height: 24px;
    margin: 0 !important;
  }

  &__checkbox {
    width: 24px;
    min-width: 24px;
    height: 24px;
    min-height: 24px;
  }
}
</style>
