<template>
  <div
    v-if="media.isMobile && (isServiceWithCategory || isServiceWithoutCategory)"
    :class="{
      'services__with-category': isServiceWithCategory,
      'services__without-category': isServiceWithoutCategory,
    }"
  >
    <v-divider class="mb-4" />
    <v-container>
      <div class="mt-6">
        <v-tooltip v-if="item.publicAccess" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="primary" class="mr-1" v-bind="attrs" v-on="on">
              mdi-check-decagram
            </v-icon>
          </template>
          <span>{{ $t('services.tooltip_public_service') }}</span>
        </v-tooltip>
        <v-btn class="services-mobile-list__name text-body-1 font-weight-medium px-0 text-none" text :to="serviceLink">
          {{ item.name }}
        </v-btn>
      </div>

      <div class="mt-6">
        <v-row no-gutters>
          <v-col cols="12" class="pr-1">
            <view-list-field :label="$t('services.some_description')">
              {{ item.shortDescription }}
            </view-list-field>
          </v-col>
        </v-row>
      </div>

      <div class="mt-6">
        <v-row no-gutters>
          <v-col cols="12" class="pr-1">
            <view-list-field :label="$t('services.contractor')">
              {{ item.contractor }}
            </view-list-field>
          </v-col>
        </v-row>
      </div>

      <div class="mt-6">
        <v-row no-gutters>
          <v-col cols="12" class="pr-1">
            <view-list-field :label="$t('services.projects')">
              {{ joinWithLimit(item.accessibleForProjects, 4) }}
            </view-list-field>
          </v-col>
        </v-row>
      </div>

      <div class="mt-6">
        <v-row no-gutters>
          <v-col cols="12" class="pr-1">
            <view-list-field :label="$t('service.order_sort')">
              {{ item.order }}
            </view-list-field>
          </v-col>
        </v-row>
      </div>

      <div class="d-flex flex-row mt-6">
        <p class="flex-grow-1 services-mobile-list__initial-amount">
          {{ item.initialAmount }}
        </p>
        <div class="d-flex align-end text-caption text--secondary">
          <div class="d-flex align-center">
            {{ $t('label.select') }}

            <base-checkbox
              :input-value="selected"
              class="services-mobile-list__checkbox ml-1 pt-0 mt-0"
              hide-details
              @change="$emit('select-item', item)"
            />
          </div>
        </div>
      </div>
    </v-container>
  </div>
  <v-list-item
    v-else-if="isButtonAddService && canUpdate"
    :to="serviceCreateLink"
    class="d-flex align-center justify-center button-add-service text-capitalize font-weight-bold"
  >
    <div class="button-add-service">
      {{ $t('services.button_add_service') }}
    </div>
  </v-list-item>

  <v-list-item v-else :to="serviceLink" class="ps-12">
    <v-simple-checkbox
      :value="selected"
      color="primary"
      :ripple="false"
      class="services-list__checkbox"
      @click.prevent="$emit('select-item', item)"
    />
    <v-row>
      <v-col cols="1" class="pa-4">
        <div class="services__item-text text-truncate text-center">
          {{ item.order }}
        </div>
      </v-col>
      <v-col cols="2" class="pa-4">
        <div class="services__item-text text-truncate" :class="{ 'pl-7': !item.publicAccess }">
          <v-tooltip v-if="item.publicAccess" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="primary" v-bind="attrs" v-on="on">
                mdi-check-decagram
              </v-icon>
            </template>
            <span>{{ $t('services.tooltip_public_service') }}</span>
          </v-tooltip>
          {{ item.name }}
        </div>
      </v-col>
      <v-col cols="2" class="pa-4">
        <div class="services__item-text text-truncate">
          {{ item.shortDescription }}
        </div>
      </v-col>
      <v-col cols="3" class="pa-4">
        <div class="services__item-text text-truncate">
          {{ joinWithLimit(item.accessibleForProjects, 3) }}
        </div>
      </v-col>
      <v-col cols="2" class="pa-4">
        <div class="services__item-text text-truncate">
          {{ item.contractor }}
        </div>
      </v-col>
      <v-col cols="2" class="pa-4">
        <div class="services__item-text text-end">
          {{ item.initialAmount }}
        </div>
      </v-col>
    </v-row>
  </v-list-item>
</template>

<script>
import ViewListField from '@/components/ViewListField.vue';
import BaseCheckbox from '@/components/BaseCheckbox.vue';
import joinWithLimit from '@/utils/joinWithLimit';
import {
  CATEGORY,
  CATEGORY_BUTTON_ADD_SERVICE,
  EMPTY_CATEGORY,
  SERVICE_WITH_CATEGORY,
  SERVICE_WITHOUT_CATEGORY,
} from '@/constants/serviceEntity';
import { SERVICES_DETAILED, SERVICES_CREATE } from '@/constants/routes';

export default {
  name: 'ItemDefault',

  components: {
    ViewListField,
    BaseCheckbox,
  },

  inject: ['media'],

  props: {
    item: {
      type: Object,
      required: true,
    },
    canUpdate: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      select: false,
    };
  },

  computed: {
    serviceLink() {
      return {
        name: SERVICES_DETAILED,
        params: { id: this.item.id },
        query: { prevPage: this.page },
      };
    },
    serviceCreateLink() {
      return {
        name: SERVICES_CREATE,
        query: { prevPage: this.page, category: this.item.categoryId, categoryName: this.item.categoryName },
      };
    },
    isCategory() {
      return this.item.type === CATEGORY || this.item.type === EMPTY_CATEGORY;
    },
    isServiceWithCategory() {
      return this.item.type === SERVICE_WITH_CATEGORY;
    },
    isServiceWithoutCategory() {
      return this.item.type === SERVICE_WITHOUT_CATEGORY;
    },
    isButtonAddService() {
      return this.item.type === CATEGORY_BUTTON_ADD_SERVICE;
    },
  },

  methods: {
    selectItem() {
      this.$emit('select-item', this.item);
    },
    joinWithLimit,
  },
};
</script>
