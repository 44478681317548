<template>
  <v-list-group v-if="isCategory" :value="openAll" multiple>
    <template v-slot:activator>
      <item-category :item="item" :can-update="canUpdate" />
    </template>
    <item-default
      v-for="child in item.children"
      :key="child.id"
      :item="child"
      :can-update="canUpdate"
      :selected="selectedItems.includes(child.id)"
      @select-item="$emit('select-item', $event)"
    />
  </v-list-group>

  <item-default
    v-else
    :item="item"
    :can-update="canUpdate"
    :selected="selectedItems.includes(item.id)"
    @select-item="$emit('select-item', $event)"
  />
</template>

<script>
import { CATEGORY, EMPTY_CATEGORY } from '@/constants/serviceEntity';
import ItemCategory from './ItemCategory.vue';
import ItemDefault from './ItemDefault.vue';

export default {
  name: 'ServicesItem',

  components: { ItemCategory, ItemDefault },

  inject: ['media'],

  props: {
    item: { type: Object, required: true },
    canUpdate: { type: Boolean, required: true },
    selectedItems: { type: Array, default: () => [] },
    openAll: { type: Boolean, default: false },
  },

  computed: {
    isCategory() {
      return this.item.type === CATEGORY || this.item.type === EMPTY_CATEGORY;
    },
  },
};
</script>
