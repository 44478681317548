<template>
  <v-menu offset-y left>
    <template v-slot:activator="{ on, attrs }">
      <icon-button v-bind="attrs" v-on="on">
        <v-icon>
          mdi-dots-horizontal
        </v-icon>
      </icon-button>
    </template>

    <v-list>
      <v-list-item v-if="!isArchive && media.isMobile" :to="serviceCreateRoute">
        <v-list-item-title>
          <v-icon right>mdi-plus</v-icon>
          {{ $t('button.add_services') }}
        </v-list-item-title>
      </v-list-item>

      <v-list-item v-if="!isArchive && media.isMobile" :to="categoryCreateRoute">
        <v-list-item-title>
          <v-icon right>mdi-plus</v-icon>
          {{ $t('button.add_category') }}
        </v-list-item-title>
      </v-list-item>

      <v-list-item @click="exportServices">
        <v-list-item-title>
          <v-icon right>mdi-file-download</v-icon>
          {{ $t('button.export') }}
        </v-list-item-title>
      </v-list-item>

      <v-list-item v-if="isArchive" @click="unarchiveServices">
        <v-list-item-title>
          <v-icon right>mdi-archive-arrow-up</v-icon>
          {{ $t('button.unarchive') }}
        </v-list-item-title>
      </v-list-item>

      <v-list-item v-if="isActive" @click="archiveServices">
        <v-list-item-title>
          <v-icon right>mdi-archive-arrow-down</v-icon>
          {{ $t('button.archive') }}
        </v-list-item-title>
      </v-list-item>

      <v-list-item v-if="media.isMobile" @click="selectAll">
        <v-list-item-title>
          <v-icon right>mdi-checkbox-marked</v-icon>
          {{ $t('button.select_all') }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
// Constants
import * as entityStatuses from '@/constants/entityStatuses';
import { SERVICES_CREATE, SERVICES_CATEGORY_CREATE } from '@/constants/routes';

// Components
import IconButton from '@/components/IconButton.vue';

export default {
  name: 'ServicesMenu',

  components: { IconButton },

  inject: ['media'],

  props: {
    status: { type: String, required: true },
    isProcessing: { type: Boolean, default: false },
  },

  computed: {
    isActive() {
      return this.status === entityStatuses.ACTIVE;
    },

    isArchive() {
      return this.status === entityStatuses.ARCHIVED;
    },

    serviceCreateRoute() {
      return { name: SERVICES_CREATE };
    },

    categoryCreateRoute() {
      return { name: SERVICES_CATEGORY_CREATE };
    },
  },

  methods: {
    unarchiveServices() {
      this.$emit('unarchive-services');
    },

    archiveServices() {
      this.$emit('archive-services');
    },

    exportServices() {
      this.$emit('export-services');
    },

    selectAll() {
      this.$emit('select-services');
    },
  },
};
</script>
